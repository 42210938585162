<template>
  <article class="article" v-lazy-container="{ selector: 'img' }">
    <section class="article__mood">
      <img :data-src="getImagePath('mood.jpg')" class="article__mood-image">
      <h1 data-aos="fade-in" class="article__mood-title">{{ $t('gerlinggarden.title') }}</h1>
    </section>
    <section class="article__text">
      <h2 data-aos="fade-up" class="article__mood-title--second">{{ $t('gerlinggarden.title') }}</h2>
      <p data-aos="fade-up">{{ $t('gerlinggarden.intro') }}</p>
      <ul data-aos="fade-up" class="article__table">
        <li v-for="(item, i) in this.json.gerlinggarden.ideas" :key="`item${i}`" class="article__table-item">
          {{ item.label }}
          <strong class="article__table-value">{{ item.value }}</strong>
        </li>
      </ul>
    </section>
    <section data-aos="fade-up" class="article__image">
      <img :data-src="getImagePath('quartier.jpg')">
    </section>
    <section class="article__video">

      <vue-vimeo-player ref="player" v-if="embedVideo" video-id="672766568" @ready="onReady"></vue-vimeo-player>

      <div :class="['article__video-placeholder', {'article__video-placeholder--hidden': showVideo == true}]">
        <div class="article__video-text">
          <p ref="vimeoPlayButton" id="vimeoPlayButton" @click="play">{{ $t('gerlinggarden.film_text') }}</p>
          <p ref="vimeoInfoText" id="vimeoInfoText" @click="showConsent">{{ $t('gerlinggarden.film_consent') }}</p>
        </div>
      </div>
    </section>
    <section class="article__columns article__columns--bg bg-corporate-medium">
      <div data-aos="fade-up" class="article__columns-item article__columns-item--title article--bg-corporate">
        <img :data-src="getImagePath('mood02.jpg')" class="article__columns-item--bg-image">
        <h2 class="article__columns-title">{{ $t('gerlinggarden.harvest_title') }}</h2>
      </div>
      <div data-aos="fade-up" data-aos-delay="300" class="article__columns-item article__columns-item--text article__columns-item--auto article--bg-corporate-medium">
        <p>{{ $t('gerlinggarden.harvest_text') }}</p>
      </div>
    </section>
    <section class="article__text">
      <img :data-src="buildingSVG" class="building">
      <h2 data-aos="fade-up">{{ $t('gerlinggarden.building_title') }}</h2>
      <p data-aos="fade-up">{{ $t('gerlinggarden.building_text') }}</p>
    </section>
    <section class="article__columns article__columns--bg">
      <div class="article__columns-item article__columns-item--text article__columns-item--even  article--bg-corporate-medium">
        <p data-aos="fade-up">{{ $t('gerlinggarden.flow_text') }}</p>
        <img :data-src="getImagePath('indoors.jpg')">
      </div>
      <div data-aos="fade-up" class="article__columns-item article__columns-item--title article--bg-corporate">
        <img :data-src="getImagePath('mood03.jpg')" class="article__columns-item--bg-image">
        <h2 data-aos="fade-up" class="article__columns-title">{{ $t('gerlinggarden.flow_title') }}</h2>
      </div>
      <div class="article__columns-item--right">
        <p v-for="(item, i) in this.json.gerlinggarden.screen_list" :key="`screen${i}`">
          <strong>{{ item.strong }}</strong> {{ item.text }}
        </p>
      </div>
    </section>
    <section class="article__text">
      <h2 data-aos="fade-up">{{ $t('gerlinggarden.screen_title') }}</h2>
      <p data-aos="fade-up">{{ $t('gerlinggarden.screen_text') }}</p>
    </section>
    <section class="article__columns article__columns--bg article__columns--stretch">
      <div class="article__columns-item article__columns-item--text article--bg-corporate-medium">
        <p class="article__columns-item--location-text" data-aos="fade-up">{{ $t('gerlinggarden.location') }}</p>

        <div class="article__columns-item--location">
          <ol class="article__location">
            <li v-for="(item, i) in this.json.gerlinggarden.location_points" :key="`location${i}`">
              {{ item.label }}
              <span v-if="item.info">{{ item.info }}</span>
            </li>
          </ol>
          <img :data-src="locationSVG" class="location">
        </div>
      </div>
    </section>
    <section>

    </section>
  </article>
</template>

<script>
// import json from `@/locales/${ $i18n.locale}.json`
import { vueVimeoPlayer } from 'vue-vimeo-player'
import * as klaro from 'klaro'

export default {
  name: 'GerlingGarden',
  components: {
    vueVimeoPlayer
  },
  metaInfo() {
    return {
      title: this.$t('gerlinggarden.meta_title'),
      meta: [
        { name: 'description', content:  this.$t('global.meta_description') },
        { name: 'keywords', content:  this.$t('global.meta_keywords') }
      ]
    }
  },
  data () {
    return {
      showVideo: false,
      embedVideo: false,
      buildingSVG: require('../assets/images/gg/building.svg'),
      locationSVG: require('../assets/images/gg/location-de.svg'),
    }
  },
  created () {
    if(this.$i18n.locale === 'en'){
      this.json = require('@/locales/en.json');
      this.locationSVG = require('../assets/images/gg/location-en.svg');
    }
    else{
      this.json = require('@/locales/de.json');
      this.locationSVG = require('../assets/images/gg/location-de.svg');
    }
  },
  updated () {
    this.$nextTick(() => {
      if(this.$i18n.locale === 'en'){
        this.json = require('@/locales/en.json');
        this.locationSVG = require('../assets/images/gg/location-en.svg');
      }
      else{
        this.json = require('@/locales/de.json');
        this.locationSVG = require('../assets/images/gg/location-de.svg');
      }
    });
  },
  mounted () {
    if (klaro.getManager().getConsent('vimeo')) {

      this.$refs.vimeoPlayButton.style.display = 'block';
      this.$refs.vimeoInfoText.style.display = 'none';
      this.embedVideo = true;
      this.showVideo = true;
    }
    else {
      this.$refs.vimeoPlayButton.style.display = 'none';
      this.$refs.vimeoInfoText.style.display = 'block';
      this.embedVideo = false;
      this.showVideo = false;
    }
    return {
      ideas: this.json.gerlinggarden.ideas,
      screen_list: this.json.gerlinggarden.screen_list,
      location_points: this.json.gerlinggarden.location_points
    }
  },
  watch: {
    embedVideo(newEmbedVideo) {
      if (!newEmbedVideo) {
        this.onReady()
      }
    }
  },
  methods: {
    onReady() {
      if (klaro.getManager().getConsent('vimeo')) {
        this.playerReady = true
      }
    },
    play () {
      if (klaro.getManager().getConsent('vimeo')) {
        this.embedVideo = true
        this.showVideo = true
        this.onReady()
        this.$nextTick(() => {
          this.$refs.player.play()
        });
      }
    },
    showConsent () {
      klaro.show()
    },
    pause () {
      this.$refs.player.pause()
    },
    getImagePath(src) {
      return require(`../assets/images/gg/${src}`)
    },
  },
}
</script>
<style scoped lang="scss">
#vimeoPlayButton {
  display: none;
}

.article__mood {
  overflow: hidden;

  &-title {
    transition: opacity .8s 0s ease-in-out;

    &.fadeout {
      opacity: 0;
    }

    &--second {
      transition: opacity .8s 0s ease-in-out;
      opacity: 0;
      margin-top: 0;

      &.fadein {
        opacity: 1;
      }

    }
  }
}

.article__image {
  @include until($tablet) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
  }
}

.article__columns-item--right {
  font-family: $corporate-font-family;

  strong {
    font-family: $corporate-font-family-strong;
  }
}

.article__columns--stretch {
  overflow: hidden;
}

.article--bg-corporate-medium {
  padding-top: 3rem;
  padding-bottom: 3rem;

  .article__columns--stretch & {
    @include desktop {
      padding-bottom: 0;
    }
  }
}

.location {
  @include desktop {
    @include grid-spacing('desktop', 1, 1, 'margin-right', '-');

    width: 70%;
    margin-left: 0;
  }

  @include desktop-xl {
    @include grid-spacing('desktop-xl', 1, 1, 'margin-right', '-');

    margin-top: -170px;
    max-width: 700px;
    width: 50vw;
  }

  margin-left: -$container-padding;
  margin-right: -$container-padding;
  width: 100vw;
  max-width: none;
}

.bg-corporate-medium {
  background-color: $color-corporate-medium;
}
</style>
